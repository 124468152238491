import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as IconPlus } from "icons/plus.svg";
import { t } from "utils/i18n";

function ButtonNew({ handleClick, parent }) {
  return (
    <li
      aria-label={t(parent)}
      onClick={handleClick}
      className="flex flex-col justify-center items-center py-2 odd:bg-gray-100"
    >
      <IconPlus />
      <span className="text-xs">{t("addNewItem")}</span>
    </li>
  );
}

ButtonNew.propTypes = {
  parent: PropTypes.string,
  handleClick: PropTypes.func,
};

export default ButtonNew;
