import React from "react";
import IconBack from "icons/back.svg";
import IconInfo from "icons/info.svg";
import { t } from "utils/i18n";
import { NavLink, useHistory } from "react-router-dom";
import { useList } from "../../context/ListContext";

function AppBar() {
  const { items } = useList();
  const history = useHistory();
  const id = history.location.pathname.split("/").pop();
  const { title } = items.find((item) => id === String(item.id)) || {};
  const heading = {
    "/loyalty-program": t("loyaltyProgram"),
    "/my-lists": t("myTrips"),
    "/settings": t("about"),
  };

  return (
    <div className="fixed top-0 left-0 right-0 flex items-center max-w-md w-full mx-auto p-4 text-center bg-white bg-opacity-95 z-10">
      <img onClick={() => history.goBack()} src={IconBack} alt="Назад" />
      <span className="mx-auto text-lg">
        {heading[history.location.pathname] || title}
      </span>
      <NavLink
        to="/settings"
        activeStyle={{
          opacity: 0,
          pointerEvents: "none",
        }}
      >
        <img src={IconInfo} alt={t("settings")} />
      </NavLink>
    </div>
  );
}

export default AppBar;
