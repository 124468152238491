import { Transform } from "../shared/transform";

export class CampingDocs extends Transform {
  constructor() {
    super();

    this.campingDocs = this.transform([
      "Посвідчення особи",
      "Готівкові кошти",
      "Банківська картка",
      "Бронь кемпінга",
      "Координати кемпінга",
    ]);

    return this.campingDocs;
  }
}
