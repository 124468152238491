import React from "react";
import { Route, Switch } from "react-router-dom";
import { ListProvider } from "context/ListContext";
import App from "../App/App";
import Home from "../Home/Home";

function Root() {
  return (
    <ListProvider>
      <div className="max-w-md min-h-screen m-auto relative px-4">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <App />
        </Switch>
      </div>
    </ListProvider>
  );
}

export default Root;
