import { Transform } from "../shared/transform";

export class Bikepacks extends Transform {
  constructor() {
    super();

    this.bikepacks = this.transform([
      "Велобаул передній",
      "Велобаул задній",
      "Велобагажник передній",
      "Велобагажник задній",
      "Внутрирамна сумка",
      "Нарамна сумка",
      "Підсидільна сумка",
      "Сумка на кермо",
      "Сумки-годівнички",
    ]);

    return this.bikepacks;
  }
}
