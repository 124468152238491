import { nanoid } from "nanoid";

export function handleFilter(array = [], id = null) {
  if (array.indexOf(id) !== -1) {
    return array.filter((idx) => idx !== id);
  } else {
    return [...array, id];
  }
}

export function removeDuplicates(myArr = [], prop = "") {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export function getTotalChecked(arr = []) {
  return arr.reduce((acc, curr) => {
    if (curr.checked && !curr.disabled) {
      return acc + 1;
    }

    return acc;
  }, 0);
}

export function getTotalLength(arr = []) {
  return arr.filter(({ disabled }) => !disabled).length;
}

export function migrateOldLists(array) {
  return array.map((item) => {
    return {
      ...item,
      outfit: Object.entries(item.outfit).reduce((acc, [name, items]) => {
        const id = nanoid();
        acc[id] = {
          id,
          name,
          items,
        };

        return acc;
      }, {}),
    };
  });
}
