import { Transform } from "../shared/transform";

export class BikeRepairKit extends Transform {
  constructor() {
    super();

    this.bikeRepairKit = this.transform([
      "Мультитул",
      "Витискач для ланцюга",
      "Замок ланцюга",
      "Насос",
      "Запасна камера",
      "Набір для ремонту проколів",
      "Бортирувальні лопатки",
      "Голка для зашивання порізів покришки",
      "Нитка для зашивання порізів покришки",
      "Суперклей",
      "Пластикові стяжки",
      "Армований скотч",
      "Змащення для ланцюга",
      "Колодки",
      "Спиці",
      "Спицевий ключ",
      "Кронштейн задньої перекидки",
      "Тросики",
      "Заглушка дискового гальма",
    ]);

    return this.bikeRepairKit;
  }
}
