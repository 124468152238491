import React from "react";
import cx from "classnames";
import Icons from "utils/icons";

function Icon({ type, season, size = "sm" }) {
  const t = Icons.getIconByIdOrName(type);
  const s = Icons.getIconByIdOrName(season);
  return (
    <div
      className={cx("relative flex justify-center items-center", {
        "w-16": size === "sm",
        "h-16": size === "sm",
        "w-20": size === "md",
        "h-20": size === "md",
      })}
    >
      <img
        style={{
          top: size === "sm" ? "0.25rem" : "0",
          left: size === "sm" ? "0.25rem" : "0",
        }}
        className={cx(`absolute left-0 top-0 rounded-full ${s?.className}`, {
          "w-6": size === "sm",
          "h-6": size === "sm",
          "w-8": size === "md",
          "h-8": size === "md",
        })}
        src={s?.url}
        alt="Сезон"
      />
      <img
        className={cx(`rounded-full h-12 w-12 ${t?.className}`, {
          "w-12": size === "sm",
          "h-12": size === "sm",
          "w-16": size === "md",
          "h-16": size === "md",
        })}
        src={t?.url}
        alt="Вид"
      />
    </div>
  );
}

export default Icon;
