import { Transform } from "../shared/transform";

export class CampingHealth extends Transform {
  constructor() {
    super();

    this.campHealth = this.transform([
      "Кемпінговий душ",
      "Рушники",
      "Сонцезахисний крем",
      "Зволожуючий крем",
      "Зубна паста",
      "Зубна щітка",
      "Зубна нитка",
      "Антиперспірант",
      "Гребінець",
      "Бритва",
      "Лопатка для туалету",
      "Засоби жіночої гігієни",
      "Туалетний папір",
      "Еко-мило",
      "Еко-шампунь",
      "Манікюрні ножиці",
      "Люстерко",
      "Спрей від комах",
    ]);

    return this.campHealth;
  }
}
