import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as IconTrash } from "icons/trash.svg";

function ItemEdit({
  item: { id, name, disabled, isUserDefined },
  handleDisabled,
  handleChange,
  handleRemove,
  handleNew,
}) {
  const inputEl = useRef(null);
  const [value, setValue] = useState(name);
  const onChange = ({ target }) => {
    setValue(target.value);
    handleChange({ id, name: target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleNew();
    inputEl.current.scrollIntoView();
  };

  useEffect(() => {
    if (!value) {
      // eslint-disable-next-line
      inputEl.current?.focus();
    }
  }, [value]);

  return (
    <li className="flex items-center px-6 py-4 odd:bg-gray-100 relative">
      <div className="flex items-center flex-1 mr-2">
        {isUserDefined ? (
          <form className="w-full flex items-center" onSubmit={handleSubmit}>
            <input
              ref={inputEl}
              data-testid="userInput"
              className="rounded-full py-2 px-4 border border-black"
              type="text"
              onChange={onChange}
              value={value}
              maxLength="40"
            />
            <IconTrash onClick={() => handleRemove(id)} className="ml-2" />
          </form>
        ) : (
          name
        )}
      </div>
      <label className="switch">
        <input
          type="checkbox"
          id="checkbox"
          checked={!disabled}
          onChange={() => handleDisabled(id)}
        />
        <div className="slider round" />
      </label>
    </li>
  );
}

ItemEdit.propTypes = {
  item: PropTypes.object.isRequired,
  handleDisabled: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleNew: PropTypes.func.isRequired,
};

export default ItemEdit;
