import { Transform } from "../shared/transform";

export class Camp extends Transform {
  constructor() {
    super();

    this.camp = this.transform([
      "Намет",
      "Футпринт",
      "Розкладачки",
      "Килимки (матраци)",
      "Надувачка (помпа) для килимка",
      "Ремкомплект для килимка",
      "Килимок-пінка для входу в намет",
      "Спальники або ковдри",
      "Простирадла",
      "Подушки",
      "Тент для навісу",
      "Відтяжки для тенту",
      "Кілочки для тенту",
      "Гамак",
      "Підвісні стропи із захистом для дерев",
      "Щітка для прибирання в наметі",
      "Кемпінговий ліхтар",
      "Запасні елементи живлення для ліхтаря",
      "Газова лампа",
      "Паливо для газової лампи",
      "Сіточки для газової лампи",
      "Кемпінгові стільці",
      "Кемпінговий стіл",
    ]);

    return this.camp;
  }
}
