import { Camp } from "./categories/camp";
import { CampingDocs } from "./categories/campingDocs";
import { CampingElectronics } from "./categories/campingElectronics";
import { CampingFood } from "./categories/campingFood";
import { CampingHealth } from "./categories/campingHealth";
import { CampingClothes } from "./categories/campingClothes";
import { CampingOther } from "./categories/campingOther";
import { CampingKitchen } from "./categories/campingKitchen";
import { FirstAidKit } from "./categories/firstAidKit";

export class Camping {
  constructor() {
    this.camp = new Camp();
    this.kitchen = new CampingKitchen();
    this.health = new CampingHealth();
    this.electronics = new CampingElectronics();
    this.docs = new CampingDocs();
    this.other = new CampingOther();
    this.clothes = new CampingClothes();
    this.firstAidKit = new FirstAidKit();
    this.food = new CampingFood();

    return this;
  }
}
