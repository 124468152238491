export class Electronics {
  constructor() {
    this.electronics = [
      { id: 69, checked: false, disabled: false, name: "Мобільний телефон" },
      {
        id: 70,
        checked: false,
        disabled: false,
        name: "Джерела енергії (пауербанки)",
      },
      {
        id: 71,
        checked: false,
        disabled: false,
        name: "Шнури для зарядки електроніки",
      },
      {
        id: 72,
        checked: false,
        disabled: false,
        name: "Гермомішок для електроніки",
      },
      { id: 73, checked: false, disabled: false, name: "Фотоапарат" },
      { id: 74, checked: false, disabled: false, name: "Карти пам'яті" },
      {
        id: 75,
        checked: false,
        disabled: false,
        name: "Акумулятори для фотоапарату",
      },
    ];

    return this.electronics;
  }
}
