import React from "react";
import { Route, Switch } from "react-router-dom";
import AppBar from "components/AppBar/AppBar";
import LoyaltyProgram from "../LoyaltyProgram/LoyaltyProgram";
import MyListItem from "../MyListItem/MyListItem";
import MyLists from "../MyLists/MyLists";
import Navigation from "components/Navigation/Navigation";
import Settings from "../Settings/Settings";

function App() {
  return (
    <>
      <AppBar />
      <div className="pb-24 pt-16 scrolling-touch">
        <Switch>
          <Route path="/my-lists/edit/:id">
            <MyListItem />
          </Route>
          <Route path="/my-lists">
            <MyLists />
          </Route>
          <Route path="/loyalty-program">
            <LoyaltyProgram />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
        </Switch>
      </div>
      <Navigation />
    </>
  );
}

export default App;
