export class Health {
  constructor() {
    this.health = [
      { id: 41, checked: false, disabled: false, name: "Косметичка" },
      { id: 42, checked: false, disabled: false, name: "Рушник з мікрофібри" },
      { id: 43, checked: false, disabled: false, name: "Сонцезахисна помада" },
      { id: 44, checked: false, disabled: false, name: "Сонцезахисний крем" },
      { id: 45, checked: false, disabled: false, name: "Зволожуючий крем" },
      { id: 46, checked: false, disabled: false, name: "Зубна паста" },
      { id: 47, checked: false, disabled: false, name: "Зубна щітка" },
      { id: 48, checked: false, disabled: false, name: "Зубна нитка" },
      { id: 49, checked: false, disabled: false, name: "Лопатка для туалету" },
      { id: 50, checked: false, disabled: false, name: "Туалетний папір" },
      { id: 51, checked: false, disabled: false, name: "Еко-мило" },
      { id: 52, checked: false, disabled: false, name: "Міні-ножиці" },
      { id: 53, checked: false, disabled: false, name: "Люстерко" },
    ];

    return this.health;
  }
}
