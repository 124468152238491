import React from "react";
import logo from "icons/logo-beta.png";
import ukraine from "images/ukraine-small.jpg";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div
      style={{
        backgroundImage: `url(${ukraine})`,
      }}
      className="grid grid-rows-3 items-center h-screen bg-center bg-cover p-6 -mx-4 overflow-hidden"
    >
      <img className="mx-auto" src={logo} alt="Логотип Пропоходи" />
      <Link
        to="/my-lists"
        style={{
          backdropFilter: "blur(10px)",
        }}
        className="p-10 text-center bg-green-200 bg-opacity-50 rounded-xl text-white text-3xl font-bold leading-tight"
      >
        Списки спорядження
      </Link>
      <Link
        to="/loyalty-program"
        style={{
          backdropFilter: "blur(10px)",
        }}
        className="self-start p-10 text-center bg-green-200 bg-opacity-50 rounded-xl text-white text-3xl font-bold leading-tight"
      >
        Запишіть на ПроПоходи
      </Link>
    </div>
  );
}

export default Home;
