import { Transform } from "../shared/transform";

export class CampingElectronics extends Transform {
  constructor() {
    super();

    this.campingElectronics = this.transform([
      "Мобільний телефон",
      "Джерела енергії (пауербанки)",
      "Сонячна панель",
      "Шнури для зарядки електроніки",
      "Фотоапарат",
      "Карти пам'яті",
      "Акумулятори для фотоапарату",
    ]);

    return this.campingElectronics;
  }
}
