import React from "react";
import PropTypes from "prop-types";
import "./Checkbox.css";

function Checkbox({ label, checked, onChange, id, className }) {
  return (
    <>
      <label className={`checkbox ${className}`}>
        <input
          className="sr-only"
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <Tick id={id} />
        <svg className="lines" viewBox="0 0 11 11">
          <path d="M5.88086 5.89441L9.53504 4.26746" />
          <path d="M5.5274 8.78838L9.45391 9.55161" />
          <path d="M3.49371 4.22065L5.55387 0.79198" />
        </svg>
        <span className="flex-1 ml-10">{label}</span>
      </label>
    </>
  );
}

function Tick({ id }) {
  return (
    <svg viewBox="0 0 21 18">
      <defs>
        <mask id={id}>
          <use className="tick" xlinkHref="#prefix__a" stroke="#fff" />
        </mask>
      </defs>
      <use className="tick" xlinkHref="#prefix__a" stroke="currentColor" />
      <use
        className="rect"
        xlinkHref="#prefix__b"
        fill="currentColor"
        stroke="currentColor"
      />
      <use xlinkHref="#prefix__c" fill="#fff" mask={`url(#${id})`} />
      <symbol
        id="prefix__a"
        viewBox="0 0 21 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.22 7.26c.5.5 2.35 2.44 3.45 4.19 3.53-5.4 6.98-7.95 10.52-9.76"
          fill="none"
          strokeWidth={2.25}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </symbol>
      <symbol
        id="prefix__c"
        viewBox="0 0 21 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18 9c0 1.446-.096 2.893-.241 4.146a5.108 5.108 0 01-4.597 4.613C11.893 17.904 10.447 18 9 18c-1.446 0-2.893-.096-4.146-.241-2.46-.241-4.356-2.154-4.613-4.613A36.945 36.945 0 010 9c0-1.446.096-2.893.241-4.162C.498 2.395 2.395.482 4.854.24 6.107.096 7.554 0 9 0c1.446 0 2.893.096 4.162.241a5.107 5.107 0 014.597 4.597C17.904 6.107 18 7.554 18 9z" />
      </symbol>
      <symbol
        id="prefix__b"
        viewBox="0 0 21 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 .75c-1.53 0-3.05.133-4.274.282a4.218 4.218 0 00-3.694 3.694C.883 5.95.75 7.471.75 9c0 1.53.133 3.05.282 4.274a4.218 4.218 0 003.694 3.694c1.223.149 2.745.282 4.274.282 1.53 0 3.05-.133 4.274-.282a4.218 4.218 0 003.694-3.694c.149-1.223.282-2.745.282-4.274 0-1.53-.133-3.05-.282-4.274a4.218 4.218 0 00-3.694-3.694C12.05.883 10.529.75 9 .75z"
          strokeWidth={1.5}
        />
      </symbol>
    </svg>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string.isRequired,
};

export default Checkbox;
