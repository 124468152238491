import Icon from "components/Icon/Icon";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { t } from "utils/i18n";
import ActionMenu from "components/ActionMenu/ActionMenu";
import Portal from "../../../../components/Portal/Portal";

function List({ items, handleRemove, handleTitle, handleCopy }) {
  const history = useHistory();
  const [isActionMenuOpen, setActionMenuOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [isModalRemoveOpen, setModalRemoveOpen] = useState(false);
  const [isModalEditOpen, setModalEditOpen] = useState(false);
  const [isModalCopyOpen, setModalCopyOpen] = useState(false);
  const [currentID, setCurrentID] = useState(null);
  const match = useRouteMatch();
  const handleChange = ({ target }) => setTitle(target.value);
  const ref = useRef();
  const handleActionMenu = (e) => {
    const { id, title } = e.target.dataset;
    e.preventDefault();

    if (id && title) {
      setCurrentID(id);
      setTitle(title);
      setActionMenuOpen((state) => !state);
    }
  };

  useEffect(() => {
    const target = ref.current;
    if (target) {
      target.addEventListener("contextmenu", handleActionMenu);
      target.addEventListener("long-press", handleActionMenu);
    }

    return () => {
      if (target) {
        target.removeEventListener("contextmenu", handleActionMenu);
        target.removeEventListener("long-press", handleActionMenu);
      }
    };
  }, []);

  if (!items.length) {
    return (
      <span
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        className="flex absolute text-center whitespace-pre font-bold"
      >
        {"Отакої! Тут жодного списку 🙁\nДавай створимо перший!"}
      </span>
    );
  }

  return (
    <>
      <div ref={ref} className="-mx-4">
        {items.map(({ id, title, type, season }) => (
          <button
            key={id}
            data-id={id}
            data-title={title}
            data-long-press-delay="500"
            className="flex items-center justify-between w-full border-b border-green-200 py-2 px-4"
            type="button"
            onClick={() => history.push(`${match.url}/edit/${id}`)}
          >
            <h3 className="flex flex-1 self-stretch items-center font-bold text-lg pointer-events-none">
              {title}
            </h3>
            <Icon type={type} season={season} size="md" />
          </button>
        ))}
      </div>

      <Portal
        isOpen={isModalRemoveOpen}
        handleDismiss={() => {
          setModalRemoveOpen(false);
          setActionMenuOpen(false);
        }}
        handleSuccess={() => {
          handleRemove(currentID);
        }}
        buttonSuccess="yes"
      >
        <span className="block py-20 font-bold text-lg">{t("removeList")}</span>
      </Portal>

      <Portal
        isOpen={isModalEditOpen}
        handleDismiss={() => {
          setModalEditOpen(false);
        }}
        handleSuccess={() => {
          handleTitle({ id: currentID, title });
        }}
        buttonSuccess="allow"
      >
        <div className="pt-6 pb-12 px-4">
          <label>
            <span className="block font-bold text-lg mb-4">{t("newName")}</span>
            <input
              name="title"
              className="rounded-full w-full py-2 px-4 border border-black"
              type="text"
              onChange={handleChange}
              value={title}
            />
          </label>
        </div>
      </Portal>

      <Portal
        isOpen={isModalCopyOpen}
        handleDismiss={() => {
          setModalCopyOpen(false);
        }}
        handleSuccess={() => {
          handleCopy({ id: currentID, title });
        }}
        buttonSuccess="allow"
      >
        <div className="pt-6 pb-12 px-4">
          <label>
            <span className="block font-bold text-lg mb-4">
              {t("copyList")}
            </span>
            <input
              name="title"
              className="rounded-full w-full py-2 px-4 border border-black"
              type="text"
              onChange={handleChange}
              value={title}
            />
          </label>
        </div>
      </Portal>

      <ActionMenu
        isOpen={isActionMenuOpen}
        handleClose={() => setActionMenuOpen(false)}
        onEdit={() => setModalEditOpen(true)}
        onRemove={() => setModalRemoveOpen(true)}
        onCopy={() => setModalCopyOpen(true)}
      />
    </>
  );
}

List.propTypes = {
  items: PropTypes.array.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleTitle: PropTypes.func.isRequired,
  handleCopy: PropTypes.func.isRequired,
};

export default List;
