import { Transform } from "../shared/transform";

export class CampingKitchen extends Transform {
  constructor() {
    super();

    this.campingKitchen = this.transform([
      "Газовий пальник або плитка",
      "Паливо для пальника або плитки",
      "Тринога для казанка",
      "Казанки з кришками",
      "Пательня з кришкою",
      "Лопатка",
      "Чайник",
      "Кавоварка",
      "Мангал",
      "Шампура",
      "Щипці для м'яса",
      "Сітка для грилю",
      "Фольга",
      "Джерело вогню",
      "Розпалювач",
      "Рукавичка-прихватка",
      "Шпажки для маршмеллоу",
      "Ложки",
      "Тарілки",
      "Горнятка",
      "Ніж",
      "Ніж для чистки овочів",
      "Ємності для води",
      "Фільтр для води",
      "Ємність для салату",
      "Дошка для нарізання",
      "Контейнери для їжі",
      "Консервний ніж",
      "Кухонний рушник",
      "Скатертина",
      "Паперові рушники",
      "Серветки",
      "Сумка-холодильник",
      "Холодильник 12V",
      "Еко-засіб для миття посуду",
      "Губка для миття посуду",
      "Пакети для сміття",
    ]);

    return this.campingKitchen;
  }
}
