export class Equipment {
  constructor() {
    this.equipment = [
      { id: 54, checked: false, disabled: false, name: "Рюкзак" },
      { id: 55, checked: false, disabled: false, name: "Рейнкавер на рюкзак" },
      { id: 56, checked: false, disabled: false, name: "Трекінгові палиці" },
      { id: 57, checked: false, disabled: false, name: "Ліхтарик" },
      {
        id: 58,
        checked: false,
        disabled: false,
        name: "Запасне джерело енергії для ліхтарика",
      },
      {
        id: 59,
        checked: false,
        disabled: false,
        name: "Гермомішки для одягу і речей",
      },
      { id: 60, checked: false, disabled: false, name: "Сонцезахисні окуляри" },
      { id: 61, checked: false, disabled: false, name: "Москітна сітка" },
    ];

    return this.equipment;
  }
}
