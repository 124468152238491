import { Transform } from "../shared/transform";

export class CampingFood extends Transform {
  constructor() {
    super();

    this.campingFood = this.transform([
      "Маршмеллоу",
      "Чай",
      "Сіль",
      "Спеції",
      "Олія",
    ]);

    return this.campingFood;
  }
}
