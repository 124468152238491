export class Other {
  constructor() {
    this.other = [
      { id: 81, checked: false, disabled: false, name: "Ремнабір" },
      { id: 83, checked: false, disabled: false, name: "Пакет для сміття" },
      { id: 84, checked: false, disabled: false, name: "Сідачка" },
      {
        id: 85,
        checked: false,
        disabled: false,
        name: "Крісло для надувного килимка",
      },
      { id: 86, checked: false, disabled: false, name: "Міні-гамак" },
    ];

    return this.other;
  }
}
