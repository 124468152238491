import { Transform } from "../shared/transform";

export class CampingOther extends Transform {
  constructor() {
    super();

    this.campingOther = this.transform([
      "Сонцезахисні окуляри",
      "Налобний ліхтарик",
      "Москітна сітка",
      "Мотузка для білизни",
      "Прищепки",
      "Мішок для брудної білизни",
      "Сідачка",
      "Ремнабір для спорядження",
      "Армований скотч",
      "Мотузка",
      "Сокира",
      "Пилка",
      "Карткові/настільні ігри",
      "Блокнот",
      "Олівець/ручка",
    ]);

    return this.campingOther;
  }
}
