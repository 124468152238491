import React, { useState } from "react";
import PropTypes from "prop-types";
import Portal from "../../../components/Portal/Portal";
import { t } from "../../../utils/i18n";

function NewCategoryButton({ addNewCategory }) {
  const [name, setName] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        className="w-full mt-4 rounded-xl border border-green-200 p-5"
        onClick={() => setModalOpen(true)}
      >
        {t("addCategory")}
      </button>
      <Portal
        isOpen={isModalOpen}
        handleDismiss={() => setModalOpen(false)}
        handleSuccess={() => {
          addNewCategory(name);
          setName("");
        }}
        buttonSuccess="create"
      >
        <div className="pt-6 pb-12 px-4">
          <label>
            <span className="block font-bold text-lg mb-4">
              {t("newCategory")}
            </span>
            <input
              name="title"
              className="rounded-full w-full py-2 px-4 border border-black"
              type="text"
              placeholder={t("categoryPlaceholder")}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </label>
        </div>
      </Portal>
    </>
  );
}

NewCategoryButton.propTypes = {
  addNewCategory: PropTypes.func.isRequired,
};

export default NewCategoryButton;
