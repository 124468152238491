import { Bivvy } from "./categories/bivvy";
import { Clothes } from "./categories/clothes";
import { Docs } from "./categories/docs";
import { Electronics } from "./categories/electronics";
import { Equipment } from "./categories/equipment";
import { FirstAidKit } from "./categories/firstAidKit";
import { Food } from "./categories/food";
import { Health } from "./categories/health";
import { Kitchen } from "./categories/kitchen";
import { Navigation } from "./categories/navigation";
import { Other } from "./categories/other";

export default class Hiking {
  constructor(season) {
    this.bivvy = new Bivvy();
    this.kitchen = new Kitchen();
    this.health = new Health();
    this.equipment = new Equipment();
    this.navigation = new Navigation();
    this.electronics = new Electronics();
    this.docs = new Docs();
    this.other = new Other();
    this.clothes = new Clothes(season);
    this.firstAidKit = new FirstAidKit();
    this.food = new Food();

    return this;
  }
}
