import React from "react";
import { Link, useLocation } from "react-router-dom";
import { t } from "utils/i18n";
import { ReactComponent as IconHome } from "icons/home.svg";
import { ReactComponent as IconNew } from "icons/new-list.svg";
import { ReactComponent as IconSearch } from "icons/search.svg";
import { ReactComponent as IconSort } from "icons/sort.svg";
import { ReactComponent as IconDiscard } from "icons/discard.svg";
import { ReactComponent as IconEdit } from "icons/edit.svg";
import { ReactComponent as IconCancel } from "icons/cancel.svg";
import { ReactComponent as IconAllow } from "icons/allow.svg";
import { useList } from "context/ListContext";

function Navigation() {
  const { pathname } = useLocation();
  const {
    openModal,
    toggleEdit,
    hideChecked,
    isEdit,
    isCheckedShown,
  } = useList();
  const buttons = {
    home: () => (
      <Link
        className="text-xs flex flex-col items-center justify-center flex-1"
        to="/"
      >
        <IconHome className="mb-1" />
        {t("home")}
      </Link>
    ),
    createNewList: () => (
      <button
        onClick={openModal}
        type="button"
        className="text-xs flex flex-col items-center justify-center flex-1"
      >
        <IconNew className="mb-1" />
        {t("create_new")}
      </button>
    ),
    search: () => (
      <button
        type="button"
        className="text-xs flex flex-col items-center justify-center"
      >
        <IconSearch className="mb-1" />
        {t("search")}
      </button>
    ),
    sort: () => (
      <button
        onClick={hideChecked}
        type="button"
        className="text-xs flex flex-col items-center justify-center flex-1"
      >
        <IconSort className="mb-1" />
        {isCheckedShown ? t("hide") : t("show")}
      </button>
    ),
    clear: () => (
      <button
        type="button"
        className="text-xs flex flex-col items-center justify-center"
      >
        <IconDiscard className="mb-1" />
        {t("clear")}
      </button>
    ),
    edit: () => (
      <button
        onClick={() => toggleEdit(isEdit ? "save" : "edit")}
        type="button"
        className="text-xs flex flex-col items-center justify-center flex-1"
      >
        <IconEdit className="mb-1" />
        {isEdit ? t("allow") : t("edit")}
      </button>
    ),
    cancelEdit: () => (
      <button
        onClick={() => toggleEdit("cancel")}
        type="button"
        className="text-xs flex flex-col items-center justify-center flex-1"
      >
        <IconCancel className="mb-1" />
        {t("cancel")}
      </button>
    ),
    saveEdit: () => (
      <button
        onClick={() => toggleEdit("save")}
        type="button"
        className="text-xs flex flex-col items-center justify-center flex-1"
      >
        <IconAllow className="mb-1" />
        {t("save")}
      </button>
    ),
  };
  const createMenu = () => {
    if (isEdit) {
      return [buttons.cancelEdit, buttons.saveEdit];
    }

    if (pathname.includes("my-lists/edit")) {
      return [buttons.home, buttons.sort, buttons.edit];
    }

    if (pathname.includes("loyalty-program") || pathname.includes("settings")) {
      return [buttons.home];
    }

    return [buttons.home, buttons.createNewList];
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 max-w-md w-full mx-auto bg-white bg-opacity-95">
      <ul className="flex justify-between px-4 pb-4 pt-2">
        {createMenu().map((Item, idx) => (
          <Item key={idx} />
        ))}
      </ul>
    </div>
  );
}

export default Navigation;
