import { Transform } from "../shared/transform";

export class Clothes extends Transform {
  constructor(season) {
    super();

    if (season === "summer") {
      return this.transform([
        "Спідня білизна",
        "Легкі трекінгові шкарпетки",
        "Трекінгова сорочка/футболка",
        "Легкі трекінгові штани",
        "Шорти",
        "Тонка флісова кофта",
        "Тонкі флісові штани",
        "Тепла кофта або пуховий светр",
        "Вітрівка",
        "Мембранна куртка",
        "Мультифункціональна пов'язка",
        "Капелюх або кепка",
        "Шапка",
        "Тонкі рукавиці",
        "Платтячко",
        "Взуття",
        "Шльопанці",
      ]);
    }

    if (season === "demy") {
      return this.transform([
        "Спідня білизна",
        "Трекінгові шкарпетки",
        "Трекінгова сорочка/футболка",
        "Трекінгові штани",
        "Флісова кофта ",
        "Флісові штани",
        "Тепла кофта або пуховий светр",
        "Вітрівка",
        "Мембранна куртка",
        "Мультифункціональна пов'язка",
        "Капелюх або кепка",
        "Шапка",
        "Тонкі рукавиці",
        "Взуття",
        "Капці для табору",
        "Термокофта",
        "Термоштани",
        "Мембранні штани",
        "Тепла мультифункціональна пов'язка",
      ]);
    }
  }
}
