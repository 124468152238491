export class Bivvy {
  constructor() {
    this.bivvy = [
      {
        id: 1,
        checked: false,
        disabled: false,
        name: "Спальник або квілт",
      },
      {
        id: 2,
        checked: false,
        disabled: false,
        name: "Компресійник для спальника",
      },
      {
        id: 3,
        checked: false,
        disabled: false,
        name: "Гермомішок для спальника",
      },
      { id: 4, checked: false, disabled: false, name: "Килимок" },
      {
        id: 5,
        checked: false,
        disabled: false,
        name: "Надувачка (помпа) для килимка",
      },
      {
        id: 6,
        checked: false,
        disabled: false,
        name: "Ремкомплект для килимка",
      },
      { id: 7, checked: false, disabled: false, name: "Подушка" },
    ];

    return this.bivvy;
  }
}
