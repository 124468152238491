import { Transform } from "../shared/transform";

export class BikeClothes extends Transform {
  constructor() {
    super();

    this.bikeClothes = this.transform([
      "Велофутболка",
      "Велокофта",
      "Велошорти",
      "Велоштани",
      "Велорукавички",
      "Мембранна куртка",
      "Вітрівка",
      "Термобілизна-кофта",
      "Термобілизна-штани",
      "Спідня білизна",
      "Шкарпетки",
      "Легка сорочка/футболка",
      "Тонка флісова кофта",
      "Тонкі флісові штани",
      "Пуховий светр",
      "Мультифункціональна пов'язка",
      "Капелюх або кепка",
      "Шапка",
      "Купальник або плавки",
      "Кросівки",
      "Капці або сандалі",
    ]);

    return this.bikeClothes;
  }
}
