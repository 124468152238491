export class Food {
  constructor() {
    this.food = [
      { id: 104, checked: false, disabled: false, name: "Чай" },
      { id: 105, checked: false, disabled: false, name: "Спеції" },
      { id: 106, checked: false, disabled: false, name: "Сіль" },
    ];

    return this.food;
  }
}
