import { DialogOverlay, DialogContent } from "@reach/dialog";
import { useTransition, animated, config } from "react-spring";
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import IconsRow from "components/IconsRow/IconsRow";
import { useHistory } from "react-router-dom";
import Icons from "utils/icons";
import { t } from "utils/i18n";
import { handleFilter } from "utils/filters";

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

function CreateList({ isOpen, close, saveList }) {
  const inputEl = useRef(null);
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    title: "",
    season: null,
    type: null,
    stove: [],
    night: null,
  });

  const isDisabled =
    step === 0
      ? !data.title || !data.season || !data.type
      : !data.title || !data.stove || !data.night;

  const isCamping = data.type === "camping";
  const buttonName = step === 1 || isCamping ? t("createList") : t("next");

  const handleInput = ({ target }) => {
    setData((state) => ({ ...state, [target.name]: target.value }));
  };

  const handleBlur = (e) => {
    e.preventDefault();
    // close the keyboard
    inputEl.current.blur();
  };

  const handleActive = (name, filter) => {
    if (filter === "stove") {
      setData((state) => ({
        ...state,
        [filter]: handleFilter(state[filter], name),
      }));
    } else {
      setData((state) => ({
        ...state,
        [filter]: name === state[filter] ? null : name,
      }));
    }
  };

  const handleClick = () => {
    if (step === 0) {
      setStep(1);
    }

    if (step === 1 || isCamping) {
      const id = saveList(data);
      close();
      history.push("/my-lists/edit/" + id);
    }
  };
  const transitions = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      transform: "scale(0.8)",
    },
    enter: {
      opacity: 1,
      transform: "scale(1)",
    },
    leave: {
      opacity: 0,
    },
    config: { tension: 280, ...config },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <AnimatedDialogOverlay
          key={key}
          style={{ opacity: props.opacity }}
          className="fixed left-0 top-0 z-10 flex justify-center items-start w-full h-full bg-opacity-75 bg-black overflow-hidden"
          onDismiss={close}
        >
          <AnimatedDialogContent
            style={props}
            aria-label={t("newList")}
            className="bg-white p-4 rounded-xl text-center max-w-xs w-full mx-4 mt-20"
          >
            <h2 className="text-xl font-bold mb-1">{t("newList")}</h2>
            <Steps step={step} />
            <form onSubmit={handleBlur}>
              <input
                ref={inputEl}
                type="text"
                name="title"
                autoComplete="off"
                className="rounded-full w-full p-2 border border-black text-center mb-3"
                placeholder="Введи назву списку"
                onChange={handleInput}
              />
            </form>
            {step === 0 ? (
              <Initial handleActive={handleActive} />
            ) : (
              <Final handleActive={handleActive} />
            )}
            <div className="flex -mx-4 -mb-4">
              <button
                type="button"
                className="bg-gray-200 w-1/2 px-4 h-16 rounded-bl-xl rounded-tr-xl leading-none"
                onClick={close}
              >
                {t("cancel")}
              </button>
              <button
                type="button"
                className="bg-green-200 w-1/2 px-4 h-16 rounded-br-xl rounded-tl-xl leading-none"
                disabled={isDisabled}
                onClick={handleClick}
              >
                <span
                  className={cx({
                    "opacity-25": isDisabled,
                  })}
                >
                  {buttonName}
                </span>
              </button>
            </div>
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
  );
}

CreateList.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  saveList: PropTypes.func.isRequired,
};

function Steps({ step = 0 }) {
  return (
    <div className="flex justify-center items-center mb-3">
      <div
        className={cx("h-3 w-3 rounded-full mr-2 bg-gray-200", {
          "bg-green-200": step === 0,
        })}
      />
      <div
        className={cx("h-3 w-3 rounded-full mr-2 bg-gray-200", {
          "bg-green-200": step === 1,
        })}
      />
    </div>
  );
}
function Initial({ handleActive }) {
  return (
    <>
      <h3 className="mb-3">{t("choseListType")}</h3>
      <div className="flex mb-3">
        <IconsRow
          single
          icons={Icons.types}
          handleActive={(name) => handleActive(name, "type")}
        />
      </div>
      <h3 className="mb-3">{t("choseListSeason")}</h3>
      <div className="flex mb-6">
        <IconsRow
          single
          icons={Icons.seasons}
          handleActive={(name) => handleActive(name, "season")}
        />
      </div>
    </>
  );
}
function Final({ handleActive }) {
  return (
    <>
      <h3 className="mb-3">{t("choseListNight")}</h3>
      <div className="flex mb-4">
        <IconsRow
          single
          icons={Icons.nights}
          handleActive={(name) => handleActive(name, "night")}
        />
      </div>
      <h3 className="mb-3">{t("choseListStove")}</h3>
      <div className="flex mb-6">
        <IconsRow
          icons={Icons.stoves}
          handleActive={(name) => handleActive(name, "stove")}
        />
      </div>
    </>
  );
}

export default CreateList;
