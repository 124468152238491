import logo from "icons/logo-beta.png";
import React from "react";
import ex from "icons/ex.png";
import gorgany from "icons/gorgany.png";
import idlo from "icons/idlo.png";
import jamesCook from "icons/james-cook.png";
import ultralightAlternatives from "icons/ultralight-alternatives.jpg";
import vershyna from "icons/vershyna.png";
import decathlon from "icons/decathlon.png";
import liteway from "icons/liteway.png";
import naturehike from "icons/naturehike.png";
import shambala from "icons/shambala.png";
import adrenalin from "icons/adrenalin.png";

function LoyaltyProgram() {
  const partners = [
    {
      logoPath: vershyna,
      url: "https://vershyna.com.ua/ua?tracking=5874d93b5b085",
      content: [
        {
          title: "Інтернет-магазин Вершина",
          text:
            "З вашої покупки через зазначене посилання ПроПоходи отримує комісію від магазину",
        },
      ],
    },
    {
      logoPath: gorgany,
      url: "https://www.gorgany.com/r/SvTx",
      content: [
        {
          title: "Інтернет-магазин Gorgany",
          text:
            "З вашої покупки через зазначене посилання ПроПоходи отримує комісію від магазину",
        },
        {
          title: "Фізичні магазини Gorgany",
          text:
            'Кодове слово "Запишіть на ПроПоходи" на касі в будь-якому магазині мережі Gorgany надає ПроПоходи комісію від магазину',
        },
      ],
    },
    {
      logoPath: ex,
      url: "https://ex.com.ua/",
      content: [
        {
          title: "Магазин Команда Екс",
          text:
            'Кодова фраза "Запишіть На ПроПоходи" в коментарі до замовлення або сказана касиру в магазинах Команда Екс та Команда Екс Travel надає вам 10% знижки на весь асортимент магазину і забезпечує ПроПоходи комісію від магазину',
        },
      ],
    },
    {
      logoPath: shambala,
      url: "https://shambala.com.ua/ua?utm_source=propohody",
      content: [
        {
          title: "Магазин Шамбала",
          text:
            'Промокод "propohody" введений в поле для промокоду на сайті інтернет-магазину Шамбала, або названий касиру в фізичному магазині Шамбала, надає 10% знижки на неакційні товари.\nТакож з вашої покупки через зазначене посилання і промокод ми отримаємо комісію на розвиток ПроПоходи.',
        },
      ],
    },
    {
      logoPath: adrenalin,
      url: "https://adrenalin.od.ua",
      content: [
        {
          title: "Магазин Адреналін",
          text:
            'Кодова фраза "Запишіть на ПроПоходи" в коментарі до замовлення на сайті adrenalin.od.ua або сказана касиру в магазині Адреналін в Одесі надасть вам 10% знижки на неакційні товари. Також з вашої покупки ми отримаємо від магазину комісію на розвиток ПроПоходи.',
        },
      ],
    },
    {
      logoPath: decathlon,
      url: "https://www.decathlon.ua/uk/",
      content: [
        {
          title: "Магазин Decathlon",
          text:
            'Промокод "propohody" надає вам 10% знижки на бренди Quechua i Forclaz в інтернет-магазині decathlon.ua і в фізичних магазинах Decathlon в Києві. У випадку купівлі в фізичному магазині - назвіть промокод касиру при під час оплати за покупку.',
        },
      ],
    },
    {
      logoPath: idlo,
      url: "https://iidlo.com/",
      content: [
        {
          title: "Похідна їжа ЇDLO",
          text:
            'Промокод "2ProP10" надає вам 10% знижки на весь асортимент виробника і забезпечує ПроПоходи комісію від магазину',
        },
      ],
    },
    {
      logoPath: jamesCook,
      url: "https://jamescook.com.ua/",
      content: [
        {
          title: "Похідна їжа James Cook",
          text:
            'Промокод "propohody" надає вам 5% знижки на весь асортимент виробника і забезпечує ПроПоходи комісію від магазину',
        },
      ],
    },
    {
      logoPath: liteway,
      url: "https://liteway.equipment/",
      content: [
        {
          title: "Український виробник полегшеного спорядження Liteway",
          text:
            'Промокод "propohody" надає вам 5% знижки на весь асортимент виробника і забезпечує ПроПоходи комісію від магазину',
        },
      ],
    },
    {
      logoPath: ultralightAlternatives,
      url: "https://ultralightalternatives.com/",
      content: [
        {
          title:
            "Український виробник надлегких аксесуарів Ultralight Alternatives",
          text:
            'Кодова фраза "Запишіть На ПроПоходи" в коментарі до замовлення надає 5% знижки на весь асортимент і забезпечує ПроПоходи комісію від магазину',
        },
      ],
    },
    {
      logoPath: naturehike,
      url: "https://nh-ua.com",
      content: [
        {
          title: "Магазин спорядження NatureHike",
          text:
            "Промокод “ProPohody” надає 5% знижки для зареєстрованих покупців і забезпечує ПроПоходи комісію від магазину",
        },
      ],
    },
  ];
  return (
    <div>
      <img className="mx-auto mb-6" src={logo} alt="Логотип Пропоходи" />
      <p className="text-center mb-2">
        Підтримай ПроПоходи без копійки витрат! Коли ви купуєте спорядження в
        наших партнерів, користуючись інформацією нижче, ПроПоходи отримують
        комісію з ваших покупок на розвиток проекту. Водночас ви не витрачаєте
        жодної додаткової копійки. А, часом, ще й отримуєте приємні знижки.
      </p>
      <h2 className="text-2xl font-bold text-center mb-2">
        Партнери та умови:
      </h2>
      {partners.map(({ logoPath, content, url }, idx) => (
        <div
          key={idx}
          style={{
            backgroundImage:
              "linear-gradient(to right, #e7e7e7 0%, #ffffff 100%)",
          }}
          className="border border-gray-200 text-center rounded rounded-xl mb-8 p-6 last:mb-0"
        >
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img
              className="w-64 mx-auto mb-2"
              src={logoPath}
              alt={content[0].title}
            />
            {content.map(({ title, text }) => (
              <section key={title} className="mb-3 last:mb-0">
                <h3 className="text-xl font-bold leading-tight mb-2">
                  {title}
                </h3>
                <p className="leading-normal whitespace-pre-line">{text}</p>
              </section>
            ))}
          </a>
        </div>
      ))}
      <h2 className="text-2xl font-bold text-center">Дякуємо за підтримку!</h2>
    </div>
  );
}

export default LoyaltyProgram;
