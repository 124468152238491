import { DialogContent, DialogOverlay } from "@reach/dialog";
import React from "react";
import PropTypes from "prop-types";
import { animated, useTransition, config } from "react-spring";
import { t } from "utils/i18n";

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

function Portal({
  isOpen,
  handleDismiss,
  handleSuccess,
  buttonSuccess,
  children,
}) {
  const transitions = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      transform: "scale(0.8)",
    },
    enter: {
      opacity: 1,
      transform: "scale(1)",
    },
    leave: {
      opacity: 0,
    },
    config: { tension: 280, ...config },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <AnimatedDialogOverlay
          key={key}
          style={{ opacity: props.opacity }}
          className="fixed left-0 top-0 z-10 flex justify-center items-start w-full h-full bg-opacity-75 bg-black overflow-hidden"
          onDismiss={handleDismiss}
        >
          <AnimatedDialogContent
            style={props}
            aria-label={t(buttonSuccess)}
            className="bg-white rounded-xl text-center max-w-xs w-full mx-4 mt-40"
          >
            {children}
            <div className="flex -mb-4">
              <button
                type="button"
                className="bg-red-200 w-1/2 px-4 h-16 rounded-bl-xl rounded-tr-xl leading-none"
                onClick={handleDismiss}
              >
                {t("cancel")}
              </button>
              <button
                type="button"
                className="bg-green-200 w-1/2 px-4 h-16 rounded-br-xl rounded-tl-xl leading-none"
                onClick={() => {
                  handleSuccess();
                  handleDismiss();
                }}
              >
                {t(buttonSuccess)}
              </button>
            </div>
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
  );
}

Portal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  buttonSuccess: PropTypes.string.isRequired,
};

export default Portal;
