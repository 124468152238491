export class Navigation {
  constructor() {
    this.navigation = [
      { id: 62, checked: false, disabled: false, name: "Путівник ПроПоходи" },
      {
        id: 63,
        checked: false,
        disabled: false,
        name: "Навігаційна програма для телефону",
      },
      {
        id: 64,
        checked: false,
        disabled: false,
        name: "Завантажити треки маршруту",
      },
      {
        id: 65,
        checked: false,
        disabled: false,
        name: "Завантажити карти маршруту",
      },
      {
        id: 66,
        checked: false,
        disabled: false,
        name: "Резервний навігаційний пристрій (навігатор/годинник)",
      },
      { id: 67, checked: false, disabled: false, name: "Ламінована карта" },
      { id: 68, checked: false, disabled: false, name: "Компас" },
    ];

    return this.navigation;
  }
}
