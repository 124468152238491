import produce from "immer";
import mixpanel from "mixpanel-browser";
import { nanoid } from "nanoid";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "utils/api";
import { migrateOldLists, removeDuplicates } from "../utils/filters";
import Outfit from "../utils/outfit";

const ListContext = createContext([{}, (s) => {}]);

function ListProvider({ children }) {
  const localItems = api.getItem("db");
  const items = useState(localItems);
  const isEdit = useState(false);
  const isCreateModalOpen = useState(false);
  const isCheckedShown = useState(true);
  const actions = useState([]);

  useEffect(() => {
    // grab default values
  }, []);

  return (
    <ListContext.Provider
      value={[items, isEdit, isCreateModalOpen, isCheckedShown, actions]}
    >
      {children}
    </ListContext.Provider>
  );
}

function useList() {
  const location = useLocation();
  const [
    [items, setItems],
    [isEdit, setEdit],
    [isCreateModalOpen, setCreateModalOpen],
    [isCheckedShown, setCheckedShown],
    [actions, setActions],
  ] = useContext(ListContext);

  const [cache, setCache] = useState([]);

  useEffect(() => {
    const obsolete = api.getItem("userLists");
    if (obsolete.length) {
      setItems(migrateOldLists(obsolete));
      localStorage.removeItem("userLists");
    }
  }, [setItems]);

  useEffect(() => {
    setEdit(false);
    setCheckedShown(true);
  }, [location, setEdit, setCheckedShown]);

  useEffect(() => {
    api.setItem("db", items);
  }, [items]);

  function openModal() {
    setCreateModalOpen(true);
  }

  function closeModal() {
    setCreateModalOpen(false);
  }

  function addItem({ listID, categoryId }) {
    const item = {
      id: nanoid(),
      checked: false,
      disabled: false,
      isUserDefined: true,
      name: "",
    };

    setActions((actions) => [...actions, { listID, categoryId }]);

    setItems(
      produce((draft) => {
        const list = draft.find((list) => listID === String(list.id));
        list.outfit[categoryId].items.push(item);
      })
    );

    try {
      mixpanel.track("Add custom item");
    } catch (e) {}
  }

  function removeItem({ listID, categoryId, id }) {
    setItems(
      produce((draft) => {
        const list = draft.find((list) => listID === String(list.id));
        const category = list.outfit[categoryId];

        category.items = category.items.filter((item) => item.id !== id);
      })
    );
  }

  function removeList(id) {
    setItems(
      produce((draft) => {
        return draft.filter((list) => list.id !== id);
      })
    );
  }

  function saveList(payload) {
    const { stove, night, type, season } = payload;
    const id = nanoid();
    const outfit = new Outfit(season);
    const list = {
      id,
      ...payload,
      outfit: outfit.getAll({ type, stove, night }),
    };
    setItems(
      produce((draft) => {
        draft.push(list);
      })
    );

    try {
      mixpanel.track("Create the list", {
        name: payload.title,
      });
    } catch (e) {}

    return id;
  }

  function copyList({ id, title }) {
    setItems(
      produce((draft) => {
        const newId = nanoid();
        const list = draft.find((list) => String(id).includes(list.id));

        return [{ ...list, title, id: newId }, ...draft];
      })
    );
  }

  function addNewCategory(listID, title) {
    setItems(
      produce((draft) => {
        const id = nanoid();
        const list = draft.find((list) => String(listID).includes(list.id));

        list.outfit[id] = {
          id,
          name: title,
          items: [],
        };
      })
    );

    try {
      mixpanel.track("Create category", {
        name: title,
      });
    } catch (e) {}
  }

  function saveListTitle({ id, title }) {
    if (!title) {
      return;
    }

    setItems(
      produce((draft) => {
        const list = draft.find((list) => String(id).includes(list.id));
        list.title = title;
      })
    );
  }

  function toggleEdit(reason = "edit") {
    if (reason === "edit") {
      setCache(items);
      setActions([]);
      setEdit((isEdit) => !isEdit);
    }

    if (reason === "cancel") {
      setEdit(false);
      setItems(cache);
    }

    if (reason === "save") {
      setEdit(false);

      removeDuplicates(actions, "listID").forEach(({ listID, categoryId }) => {
        setItems(
          produce((draft) => {
            const list = draft.find((list) => String(listID).includes(list.id));
            const category = list.outfit[categoryId];

            category.items = category.items.filter((item) => item.name?.trim());
          })
        );
      });
    }
  }

  function hideChecked() {
    setCheckedShown((isCheckedShown) => !isCheckedShown);
  }

  function updateItem({ listID, categoryId, id, reason, name }) {
    setItems(
      produce((draft) => {
        const list = draft.find((list) => listID === String(list.id));
        const category = list.outfit[categoryId];

        category.items = category.items.map((item) => {
          if (item.id === id) {
            item[reason] = !item[reason];
            item.name = name?.trim();
          }

          return item;
        });
      })
    );
  }

  return {
    items,
    isCreateModalOpen,
    isEdit,
    isCheckedShown,
    hideChecked,
    openModal,
    closeModal,
    saveList,
    updateItem,
    toggleEdit,
    addItem,
    removeItem,
    removeList,
    copyList,
    saveListTitle,
    addNewCategory,
  };
}

export { ListProvider, useList };
