export class Kitchen {
  constructor() {
    this.kitchen = [
      { id: 21, checked: false, disabled: false, name: "Джерело вогню" },
      {
        id: 22,
        checked: false,
        disabled: false,
        name: "Резервне джерело вогню в герметичній упаковці",
      },
      { id: 23, checked: false, disabled: false, name: "Ложка" },
      { id: 24, checked: false, disabled: false, name: "Тарілка" },
      { id: 25, checked: false, disabled: false, name: "Горнятко" },
      { id: 26, checked: false, disabled: false, name: "Ніж" },
      { id: 29, checked: false, disabled: false, name: "Ємності для води" },
      { id: 30, checked: false, disabled: false, name: "Фільтр для води" },
      {
        id: 31,
        checked: false,
        disabled: false,
        name: "Губка для миття посуду",
      },
      {
        id: 32,
        checked: false,
        disabled: false,
        name: "Еко-засіб для миття посуду",
      },
    ];

    return this.kitchen;
  }
}
