import { Transform } from "../shared/transform";

export class BikeEquipment extends Transform {
  constructor() {
    super();

    this.bikeEquipment = this.transform([
      "Велошолом",
      "Велокомп'ютер",
      "Велозамок",
      "Ключі до замка",
      "Сонцезахисні окуляри",
      "Гермомішки для одягу і речей",
      "Налобний ліхтарик",
      "Велофара передня",
      "Велофара задня",
      "Запасні елементи живлення для освітлення",
      "Тримач для телефону",
      "Фляги для води",
      "Чохол для велосипеда",
      "Сідачка",
    ]);

    return this.bikeEquipment;
  }
}
