export class Optional {
  constructor() {
    this.optional = {
      tent: [
        { id: 8, checked: false, disabled: false, name: "Намет" },
        { id: 9, checked: false, disabled: false, name: "Футпринт" },
      ],
      floorLessTent: [
        { id: 10, checked: false, disabled: false, name: "Тент" },
        { id: 11, checked: false, disabled: false, name: "Відтяжки для тенту" },
        { id: 12, checked: false, disabled: false, name: "Кілочки для тенту" },
        { id: 13, checked: false, disabled: false, name: "Бівачний мішок" },
        { id: 14, checked: false, disabled: false, name: "Футпринт" },
      ],
      hammock: [
        { id: 15, checked: false, disabled: false, name: "Гамак" },
        {
          id: 16,
          checked: false,
          disabled: false,
          name: "Підвісні стропи із захистом для дерев",
        },
        { id: 17, checked: false, disabled: false, name: "Тент" },
        { id: 18, checked: false, disabled: false, name: "Відтяжки для тенту" },
        { id: 19, checked: false, disabled: false, name: "Кілочки для тенту" },
        { id: 20, checked: false, disabled: false, name: "Андерквілт" },
      ],
      stoveKit: [
        {
          id: 33,
          checked: false,
          disabled: false,
          name: "Інтегрована система",
        },
        { id: 34, checked: false, disabled: false, name: "Газовий балон" },
      ],
      stove: [
        { id: 35, checked: false, disabled: false, name: "Пальник" },
        {
          id: 36,
          checked: false,
          disabled: false,
          name: "Паливо для пальника",
        },
        { id: 37, checked: false, disabled: false, name: "Казанок" },
      ],
      fire: [
        { id: 38, checked: false, disabled: false, name: "Казанок" },
        { id: 39, checked: false, disabled: false, name: "Тринога" },
      ],
    };

    return this.optional;
  }
}
