import { Biking } from "../models/biking";
import { Camping } from "../models/camping";
import Hiking from "../models/hiking";
import { Optional } from "../models/categories/optional";
import { removeDuplicates } from "./filters";
import { nanoid } from "nanoid";

class Outfit {
  constructor(season = "summer") {
    this.hiking = new Hiking(season);
    this.biking = new Biking();
    this.camping = new Camping();
    this.optional = new Optional();
  }

  getList(type, name, filter) {
    const id = nanoid();
    if (!filter) {
      return {
        [id]: {
          id,
          name,
          items: this[type][name],
        },
      };
    }

    if (!this[type] || !this[type][name]) {
      return {};
    }

    if (Array.isArray(filter)) {
      const result = filter.reduce(
        (acc, filter) => {
          const result = [...acc, ...this.optional[filter]];

          return removeDuplicates(result, "name");
        },
        [...this[type][name]]
      );

      return {
        [id]: {
          id,
          name,
          items: result,
        },
      };
    }

    return {
      [id]: {
        id,
        name,
        items: [...this.optional[filter], ...this[type][name]],
      },
    };
  }

  getAll({ type = "hiking", night = "tent", stove = ["fire"] } = {}) {
    const bivvy = this.getList(type, "bivvy", night);
    const kitchen = this.getList(type, "kitchen", stove);
    const health = this.getList(type, "health");
    const equipment = this.getList(type, "equipment");
    const navigation = this.getList(type, "navigation");
    const electronics = this.getList(type, "electronics");
    const docs = this.getList(type, "docs");
    const other = this.getList(type, "other");
    const clothes = this.getList(type, "clothes");
    const firstAidKit = this.getList(type, "firstAidKit");
    const food = this.getList(type, "food");
    const bikepacks = this.getList(type, "bikepacks");
    const bikeRepairKit = this.getList(type, "bikeRepairKit");
    const camp = this.getList(type, "camp");

    if (type === "biking") {
      return {
        ...bivvy,
        ...kitchen,
        ...bikepacks,
        ...bikeRepairKit,
        ...health,
        ...equipment,
        ...navigation,
        ...electronics,
        ...docs,
        ...other,
        ...clothes,
        ...firstAidKit,
        ...food,
      };
    }

    if (type === "camping") {
      return {
        ...camp,
        ...kitchen,
        ...health,
        ...electronics,
        ...docs,
        ...other,
        ...clothes,
        ...firstAidKit,
        ...food,
      };
    }

    return {
      ...bivvy,
      ...kitchen,
      ...health,
      ...equipment,
      ...navigation,
      ...electronics,
      ...docs,
      ...other,
      ...clothes,
      ...firstAidKit,
      ...food,
    };
  }
}

export default Outfit;
