export default {
  home: "Головна",
  create_new: "Створити новий",
  search: "Знайти",
  myTrips: "Мої списки",
  hiking: "Піший\nпохід",
  biking: "Вело\nпохід",
  water: "Водний\nпохід",
  ski: "Лижний\nпохід",
  summer: "Літо",
  winter: "Зима",
  demy: "Міжсезоння",
  fire: "Багаття",
  stove: "Пальник",
  stoveKit: "Інтегрована\nсистема",
  tent: "В наметі",
  hammock: "В гамаку",
  floorLessTent: "Під тентом",
  bivvy: "Бівак",
  kitchen: "Кухня",
  navigation: "Навігація",
  health: "Гігієна",
  electronics: "Електроніка",
  docs: "Документи і гроші",
  other: "Інше",
  clothes: "Одяг і взуття",
  equipment: "Спорядження на хід",
  show: "Показати зібране",
  hide: "Сховати зібране",
  clear: "Скинути",
  edit: "Редагувати",
  addNewItem: "Додати новий елемент",
  cancel: "Скасувати",
  allow: "Застосувати",
  removeList: "Видалити список?",
  yes: "Ще б пак!",
  newName: "Нова назва",
  next: "Далі",
  newList: "Новий список",
  listName: "Назва списку:",
  choseListType: "Обери тип мандрівки:",
  choseListSeason: "Обери сезон мандрівки:",
  choseListNight: "Як організуємо ночівлю?",
  choseListStove: "На чому будемо готувати?",
  loyaltyProgram: "Програма лояльностi",
  settings: "Налаштування",
  about: "Про додаток",
  createList: "Створити список",
  camping: "Кемпінг",
  firstAidKit: "Аптечка",
  food: "Їжа",
  bikeRepairKit: "Ремкомлект для вело",
  bikepacks: "Велосумки",
  camp: "Табір",
  rename: "Перейменувати",
  remove: "Видалити",
  actionMenu: "Меню дій",
  save: "Зберегти",
  duplicate: "Копіювати",
  copy: "Копія",
  copyList: "Копіювання списку",
  addCategory: "Додати категорію",
  newCategory: "Нова категорія",
  create: "Створити",
  categoryPlaceholder: "Введи назву категорії",
};
