import { Transform } from "../shared/transform";

export class CampingClothes extends Transform {
  constructor() {
    super();

    this.campingClothes = this.transform([
      "Капці для табору",
      "Кросівки",
      "Шорти",
      "Штани",
      "Купальник або плавки",
      "Футболки",
      "Шкарпетки",
      "Спідня білизна",
      "Капелюх",
      "Бандана",
      "Фліска",
      "Пуховка",
      "Піжама",
      "Дощовик",
    ]);

    return this.campingClothes;
  }
}
