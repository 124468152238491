import { nanoid } from "nanoid";

export class Transform {
  transform(arr) {
    return arr.map((name) => ({
      id: nanoid(),
      checked: false,
      disabled: false,
      name,
    }));
  }
}
