import Bike from "icons/bike.svg";
import Demy from "icons/demy.svg";
import Hiking from "icons/hiking.svg";
import Summer from "icons/summer.svg";
import Winter from "icons/winter.svg";
import Fire from "icons/fire.svg";
import Stove from "icons/stove.svg";
import StoveKit from "icons/stove-kit.svg";
import Tent from "icons/tent.svg";
import Hammock from "icons/hammock.svg";
import FloorLessTent from "icons/floorless-tent.svg";
import Camping from "icons/camping.svg";

class Icons {
  constructor() {
    this.defaultTypes = [
      {
        id: 1,
        name: "hiking",
        url: Hiking,
        className: "bg-green-200 border-green-200",
        active: false,
        disabled: false,
      },
      {
        id: 2,
        name: "biking",
        url: Bike,
        className: "bg-green-200 border-green-200",
        active: false,
        disabled: false,
      },
      // {
      //   id: 3,
      //   name: "water",
      //   url: Water,
      //   className: "bg-green-200 border-green-200",
      //   active: false,
      //   disabled: true,
      // },
      // {
      //   id: 4,
      //   name: "ski",
      //   url: Ski,
      //   className: "bg-green-200 border-green-200",
      //   active: false,
      //   disabled: true,
      // },
      {
        id: 14,
        name: "camping",
        url: Camping,
        className: "bg-green-200 border-green-200",
        active: false,
        disabled: false,
      },
    ];

    this.defaultSeasons = [
      {
        id: 5,
        name: "summer",
        url: Summer,
        className: "bg-summer border-summer",
        active: false,
        disabled: false,
      },
      {
        id: 6,
        name: "demy",
        url: Demy,
        className: "bg-demy border-demy",
        active: false,
        disabled: false,
      },
      {
        id: 7,
        name: "winter",
        url: Winter,
        className: "bg-winter border-winter",
        active: false,
        disabled: true,
      },
    ];

    this.defaultStoves = [
      {
        id: 8,
        name: "fire",
        url: Fire,
        className: "bg-green-200 border-green-200",
        active: false,
      },
      {
        id: 9,
        name: "stove",
        url: Stove,
        className: "bg-green-200 border-green-200",
        active: false,
      },
      {
        id: 10,
        name: "stoveKit",
        url: StoveKit,
        className: "bg-green-200 border-green-200",
        active: false,
      },
    ];

    this.defaultNights = [
      {
        id: 11,
        name: "tent",
        url: Tent,
        className: "bg-green-200 border-green-200",
        active: false,
      },
      {
        id: 12,
        name: "hammock",
        url: Hammock,
        className: "bg-green-200 border-green-200",
        active: false,
      },
      {
        id: 13,
        name: "floorLessTent",
        url: FloorLessTent,
        className: "bg-green-200 border-green-200",
        active: false,
      },
    ];

    this.icons = [
      ...this.defaultTypes,
      ...this.defaultSeasons,
      ...this.defaultNights,
      ...this.defaultStoves,
    ];
  }

  get types() {
    return this.defaultTypes;
  }

  get seasons() {
    return this.defaultSeasons;
  }

  get stoves() {
    return this.defaultStoves;
  }

  get nights() {
    return this.defaultNights;
  }

  getIconByIdOrName(name) {
    // we can remove id check later
    return this.icons.find((icon) => icon.name === name || icon.id === name);
  }
}

export default new Icons();
