class Api {
  setItem(name = "", value = "") {
    localStorage.setItem(name, JSON.stringify(value));
  }

  getItem(name) {
    const item = localStorage.getItem(name);

    return item ? JSON.parse(item) : [];
  }
}

export default new Api();
