import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { t } from "utils/i18n";

function IconsRow({ icons = [], handleActive, single = false }) {
  const [state, setState] = useState(icons);

  const handleClick = (name) => {
    const icons = state.map((icon) => {
      if (icon.name === name) {
        return {
          ...icon,
          active: !icon.active,
        };
      }

      if (single) {
        return {
          ...icon,
          active: false,
        };
      }

      return icon;
    });

    setState(icons);
    handleActive(name);
  };

  return state.map(({ id, name, url, active, className, disabled }) => (
    <button
      key={id}
      aria-label={name}
      type="button"
      className="flex flex-col items-center flex-1"
      onClick={() => handleClick(name)}
      disabled={disabled}
      style={{
        opacity: disabled ? 0.2 : 1,
      }}
    >
      <span
        className={cx(
          "rounded-full h-16 w-16 flex items-center justify-center border border-black transition-colors duration-200 ease-in-out mx-auto mb-1",
          {
            [className]: active,
          }
        )}
      >
        <img src={url} alt={name} />
      </span>
      <span className="text-xs leading-none whitespace-pre">{t(name)}</span>
    </button>
  ));
}

IconsRow.propTypes = {
  icons: PropTypes.array.isRequired,
  handleActive: PropTypes.func.isRequired,
  single: PropTypes.bool,
};

export default IconsRow;
