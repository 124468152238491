export class Docs {
  constructor() {
    this.docs = [
      { id: 76, checked: false, disabled: false, name: "Посвідчення особи" },
      { id: 77, checked: false, disabled: false, name: "Готівкові кошти" },
      { id: 78, checked: false, disabled: false, name: "Банківська картка" },
      {
        id: 79,
        checked: false,
        disabled: false,
        name: "Дозвіл від прикордонників",
      },
      {
        id: 80,
        checked: false,
        disabled: false,
        name: "Водонепроникний чохол",
      },
    ];

    return this.docs;
  }
}
