export class FirstAidKit {
  constructor() {
    this.firstAidKit = [
      {
        id: 107,
        checked: false,
        disabled: false,
        name: "Знезаражувальне",
      },
    ];

    return this.firstAidKit;
  }
}
