import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion";
import { ReactComponent as IconArrowDown } from "icons/arrow-down.svg";
import { ReactComponent as IconResult } from "icons/result.svg";
import { useParams, Redirect } from "react-router-dom";
import { t } from "utils/i18n";
import "react-circular-progressbar/dist/styles.css";
import { useList } from "../../context/ListContext";
import { getTotalChecked, getTotalLength } from "../../utils/filters";
import Item from "./components/Item";
import ButtonNew from "./components/ButtonNew";
import ItemEdit from "./components/ItemEdit";
import NewCategoryButton from "./components/NewCategoryButton";
import cx from "classnames";

function MyListItem() {
  const [indices, setIndices] = useState([]);
  const { id: listID } = useParams();

  const {
    items,
    updateItem,
    isEdit,
    isCheckedShown,
    addItem,
    removeItem,
    addNewCategory,
  } = useList();
  const { outfit } = items.find((item) => listID === String(item.id)) || {};
  const handleClick = ({ categoryId, id, reason, name }) => {
    updateItem({ listID, categoryId, id, reason, name });
  };
  const handleChange = ({ categoryId, id, name }) => {
    updateItem({ listID, categoryId, id, name });
  };

  const handleAccordion = (toggledIndex) => {
    if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex)
      );
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  };

  if (!outfit) {
    return <Redirect to="/my-lists" />;
  }

  return (
    <>
      <Accordion index={indices} onChange={handleAccordion}>
        {Object.entries(outfit).map(([key, category]) => {
          const totalLength = getTotalLength(category.items);
          const totalChecked = getTotalChecked(category.items);
          const isEmpty = totalLength === 0;

          return (
            <AccordionItem
              key={key}
              className="mb-4 last:mb-0 rounded-xl border border-green-200 overflow-hidden"
            >
              <AccordionButton className="w-full p-5 flex justify-between items-center">
                <div className="w-12 h-12 mr-4">
                  {!isEmpty && totalChecked === totalLength ? (
                    <IconResult />
                  ) : (
                    <CircularProgressbar
                      value={totalChecked}
                      maxValue={isEmpty ? undefined : totalLength}
                      text={`${totalChecked}/${totalLength}`}
                      strokeWidth={6}
                      styles={buildStyles({
                        textSize: "1.75rem",
                        pathColor: "#75a93a",
                        textColor: "#000000",
                      })}
                    />
                  )}
                </div>
                <span className="text-lg font-bold leading-tight text-left mr-auto">
                  {t(category.name)}
                </span>
                <IconArrowDown
                  className={cx({
                    hidden:
                      totalChecked === totalLength &&
                      !isCheckedShown &&
                      !isEdit,
                  })}
                  height={48}
                  width={48}
                />
              </AccordionButton>
              <AccordionPanel className="focus:outline-none">
                <ul className="overflow-hidden">
                  {category.items
                    .filter(
                      ({ checked }) => isEdit || isCheckedShown || !checked
                    )
                    .map((item) => {
                      if (isEdit) {
                        return (
                          <ItemEdit
                            key={item.id}
                            item={item}
                            handleDisabled={(id) =>
                              handleClick({
                                categoryId: category.id,
                                id,
                                reason: "disabled",
                                name: item.name,
                              })
                            }
                            handleChange={({ id, name }) =>
                              handleChange({
                                categoryId: category.id,
                                id,
                                name,
                              })
                            }
                            handleRemove={(id) =>
                              removeItem({
                                listID,
                                categoryId: category.id,
                                id,
                              })
                            }
                            handleNew={() =>
                              addItem({ listID, categoryId: category.id })
                            }
                          />
                        );
                      }

                      if (!item.disabled) {
                        return (
                          <Item
                            key={item.id}
                            item={item}
                            handleChecked={(id) =>
                              handleClick({
                                categoryId: category.id,
                                id,
                                reason: "checked",
                                name: item.name,
                              })
                            }
                          />
                        );
                      }

                      return null;
                    })}
                  {isEdit ? (
                    <ButtonNew
                      parent={category.name}
                      handleClick={() =>
                        addItem({ listID, categoryId: category.id })
                      }
                    />
                  ) : null}
                </ul>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      <NewCategoryButton
        addNewCategory={(name) => addNewCategory(listID, name)}
      />
    </>
  );
}

export default MyListItem;
