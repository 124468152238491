import { useList } from "context/ListContext";
import React, { useEffect, useState } from "react";
import CreateList from "./components/CreateList/CreateList";
// eslint-disable-next-line
import Filters from "./components/Filters/Filters";
import List from "./components/List/List";

function MyLists() {
  const {
    items: defaultItems,
    isCreateModalOpen,
    closeModal,
    saveList,
    removeList,
    saveListTitle,
    copyList,
  } = useList();
  const [items, setItems] = useState(defaultItems);

  useEffect(() => {
    setItems(defaultItems);
  }, [defaultItems]);

  return (
    <>
      {/*<Filters*/}
      {/*  key={defaultItems.length}*/}
      {/*  items={defaultItems}*/}
      {/*  onChange={setItems}*/}
      {/*/>*/}
      <List
        items={items}
        handleRemove={removeList}
        handleCopy={copyList}
        handleTitle={saveListTitle}
      />
      <CreateList
        key={isCreateModalOpen}
        close={closeModal}
        isOpen={isCreateModalOpen}
        saveList={saveList}
      />
    </>
  );
}

export default MyLists;
