import { BikeClothes } from "./categories/bikeClothes";
import { BikeEquipment } from "./categories/bikeEquipment";
import { Bikepacks } from "./categories/bikepacks";
import { BikeRepairKit } from "./categories/bikeRepairKit";
import { Bivvy } from "./categories/bivvy";
import { Docs } from "./categories/docs";
import { Electronics } from "./categories/electronics";
import { FirstAidKit } from "./categories/firstAidKit";
import { Food } from "./categories/food";
import { Health } from "./categories/health";
import { Kitchen } from "./categories/kitchen";
import { Navigation } from "./categories/navigation";
import { Other } from "./categories/other";

export class Biking {
  constructor() {
    this.bivvy = new Bivvy();
    this.kitchen = new Kitchen();
    this.bikepacks = new Bikepacks();
    this.equipment = new BikeEquipment();

    this.bikeRepairKit = new BikeRepairKit();
    this.electronics = new Electronics();
    this.docs = new Docs();

    this.navigation = new Navigation();
    this.health = new Health();
    this.clothes = new BikeClothes();
    this.other = new Other(); // Ремнабір
    this.food = new Food();
    this.firstAidKit = new FirstAidKit();

    return this;
  }
}
