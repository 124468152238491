import React from "react";
import logo from "icons/logo-beta.png";
import patreon from "icons/patreon.png";
import { ReactComponent as FacebookLogo } from "icons/facebook.svg";
import { ReactComponent as TelegramLogo } from "icons/telegram.svg";
import { ReactComponent as InstagramLogo } from "icons/instagram.svg";
import { ReactComponent as YoutubeLogo } from "icons/youtube.svg";

function Settings() {
  return (
    <>
      <img className="mx-auto" src={logo} alt="Логотип Пропоходи" />
      <section className="grid grid-rows-1 gap-1">
        <section className="grid text-right col-end-1">
          <span>Задум -</span>
          <span>Консультант -</span>
          <span>Дизайн -</span>
          <span>Розробка -</span>
        </section>
        <section className="grid">
          <span className="font-bold">Антон Прохоров</span>
          <span className="font-bold">Ніка Кудрицька</span>
          <span className="font-bold leading-tight">Дмитро Скуратівський</span>
          <span className="font-bold">Влад Слепкан</span>
        </section>
      </section>
      <hr className="border-gray-200 my-6" />
      <section>
        <h2 className="text-md font-bold mb-4 text-center">
          ПроПоходи на Патреоні
        </h2>
        <a
          href="https://www.patreon.com/propohody"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="w-12 h-12 m-auto"
            src={patreon}
            alt="ПроПоходи на Патреоні"
          />
        </a>
      </section>
      <hr className="border-gray-200 my-6" />
      <section>
        <h2 className="text-md font-bold mb-4 text-center">
          ПроПоходи в соцмережах
        </h2>
        <section className="flex justify-around">
          <a
            href="https://www.youtube.com/propohody"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeLogo />
          </a>

          <a
            href="https://www.instagram.com/propohody"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramLogo />
          </a>
          <a
            href="https://www.facebook.com/propohody"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookLogo />
          </a>
          <a
            href="https://www.t.me/propohody"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TelegramLogo />
          </a>
        </section>
      </section>
      <hr className="border-gray-200 my-6" />
      <section className="">
        <a
          className="block text-green-200 text-center mb-1"
          href="mailto:propohody@gmail.com"
        >
          propohody@gmail.com
        </a>
        <a
          className="block text-green-200 text-center"
          href="https://propohody.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          www.propohody.com
        </a>
      </section>
      <hr className="border-gray-200 my-6" />
      <p className="text-center my-0">версія 1.4.0</p>
    </>
  );
}

export default Settings;
