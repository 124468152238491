import { DialogContent, DialogOverlay } from "@reach/dialog";
import PropTypes from "prop-types";
import React from "react";
import { animated, config, useTransition } from "react-spring";
import { t } from "utils/i18n";
import { ReactComponent as IconDuplicate } from "icons/duplicate.svg";
import { ReactComponent as IconDelete } from "icons/delete.svg";
import { ReactComponent as IconRename } from "icons/rename.svg";

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

function ActionMenu({ isOpen, handleClose, onEdit, onRemove, onCopy }) {
  const transitions = useTransition(isOpen, null, {
    from: {
      backgroundOpacity: "0",
      height: "0px",
    },
    enter: {
      backgroundOpacity: "0.25",
      height: "176px",
    },
    leave: { backgroundOpacity: "0", height: "0px" },
    config: { tension: 280, ...config },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <AnimatedDialogOverlay
          key={key}
          style={{
            backgroundColor: props.backgroundOpacity.interpolate(
              (o) => `rgba(0, 0, 0, ${o})`
            ),
          }}
          className="fixed left-0 top-0 z-10 flex justify-center items-end w-full h-full bg-black"
          onDismiss={handleClose}
        >
          <AnimatedDialogContent
            aria-label={t("actionMenu")}
            style={{ height: props.height }}
            className="bg-white max-w-md w-full outline-none"
          >
            <ul className="text-lg">
              <li
                onClick={() => {
                  onCopy();
                  handleClose();
                }}
                className="px-5 my-5 flex items-center"
              >
                <IconDuplicate className="mr-3" />
                {t("duplicate")}
              </li>
              <li
                onClick={() => {
                  onEdit();
                  handleClose();
                }}
                className="px-5 my-5 flex items-center"
              >
                <IconRename className="mr-3" />
                {t("rename")}
              </li>
              <li
                onClick={() => {
                  onRemove();
                  handleClose();
                }}
                className="px-5 my-5 flex items-center"
              >
                <IconDelete className="mr-3" />
                {t("remove")}
              </li>
            </ul>
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
  );
}

ActionMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ActionMenu;
