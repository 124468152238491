import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Checkbox from "components/Checkbox/Checkbox";

function Item({ item: { id, name, checked }, handleChecked }) {
  return (
    <li className="flex items-center px-6 py-4 odd:bg-gray-100 relative">
      <Checkbox
        id={id}
        label={name}
        checked={checked}
        onChange={() => handleChecked(id)}
        className={cx("flex items-center leading-tight flex-1", {
          "line-through": checked,
          "text-gray-300": checked,
        })}
      />
    </li>
  );
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  handleChecked: PropTypes.func.isRequired,
};

export default Item;
